import {Injectable} from '@angular/core';
import {CanMatch, Route, UrlSegment, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MatchPublicPageBlogPostGuard implements CanMatch {

  constructor() {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return !['k8', 'preschool', 'letters', 'words', 'adjectives'].includes(segments[0].path);
  }

}
